import LocalStorageService from '~/services/localStorage.service';

export const getOrderedColumns = (
  columns: unknown[],
  localStorageKey: string,
  disableColumnReorder: boolean,
) => {
  if (disableColumnReorder) {
    return columns;
  }

  const data = LocalStorageService.getObjectFromLocalStorage(localStorageKey);

  if (!data?.columnOrderModel) {
    return columns;
  }

  // Get all column fields for validation
  const columnFields = new Set(columns.map((col: any) => col.field));

  const storedFields = new Set(data.columnOrderModel);

  const isValidOrder = [...columnFields].every((field) =>
    storedFields.has(field),
  );

  if (!isValidOrder) {
    return columns;
  }

  const orderMap = new Map(
    data.columnOrderModel.map((field: string, index: number) => [field, index]),
  );

  const indexedColumns = columns.map((column, defaultIndex) => ({
    index: orderMap.get(column.field) ?? columns.length + defaultIndex,
    ...column,
  }));

  return indexedColumns.sort((a, b) => a.index - b.index);
};
