import {
  ArrowDropDown as ArrowDropDownIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import clsx from 'clsx';
import { memo, type MouseEvent, type ReactNode } from 'react';

import { type MainNavItemChildType, type MainNavItemType } from '../types';

import { SelectedNavItemIndicator } from './SelectedNavItemIndicator';

type P = {
  handleChildItemsToggle?: () => void;
  handleContextMenu: (event: MouseEvent, name: string, route: string) => void;
  handleItemClick: (name: string, route: string, callback?: () => void) => void;
  isChildItem?: boolean;
  isChildItemOpen?: (itemName: string) => boolean;
  isNavCollapsed: boolean;
  item: MainNavItemType | MainNavItemChildType;
  withSalesTooltip: (element: ReactNode, ...arguments_: boolean[]) => ReactNode;
  withTooltip: (element: ReactNode, tooltip: string) => ReactNode;
};

export const NavItem = memo<P>(
  ({
    handleChildItemsToggle,
    handleContextMenu,
    handleItemClick,
    isChildItem = false,
    isChildItemOpen,
    isNavCollapsed,
    item,
    withSalesTooltip,
    withTooltip,
  }) => {
    const {
      callback,
      children,
      disabled,
      disabledByClientPortal,
      disabledByModuleInvoiceRestriction,
      disabledByPackageBasicRestriction,
      error,
      filledIcon,
      isSelected,
      name,
      outlinedIcon,
      route,
    } = item;

    const isParentItem = 'children' in item;
    const hasChildren = isParentItem && children && children.length > 1;
    const singleChild =
      isParentItem && children && children.length === 1 ? children[0] : null;
    const isOpen =
      isParentItem && isChildItemOpen ? isChildItemOpen(name) : false;
    const hasSelectedChild =
      hasChildren && children.some(({ isSelected }) => isSelected);
    const isHighlighted =
      isSelected ||
      singleChild?.isSelected ||
      hasSelectedChild ||
      (isOpen && route);

    const handleClick = () => {
      if (hasChildren) {
        handleChildItemsToggle?.();
      } else if (singleChild) {
        handleItemClick(
          singleChild.name,
          singleChild.route,
          singleChild.callback,
        );
      } else if (route) {
        handleItemClick(name, route, callback);
      } else {
        console.warn(`No route defined for menu item: ${name}`);
      }
    };

    const isDisabled =
      disabled ||
      disabledByClientPortal ||
      disabledByModuleInvoiceRestriction ||
      disabledByPackageBasicRestriction;

    const showIndicator = isSelected || singleChild?.isSelected;

    const listItemContent = (
      <>
        <SelectedNavItemIndicator isSelected={showIndicator} />
        <div
          data-testid={item.buttonTestId}
          className="flex w-full items-center"
        >
          {withTooltip(
            <ListItemIcon
              classes={{
                root: clsx(
                  'relative items-center justify-center transition-colors duration-300',
                  isChildItem ? '!min-w-8' : '!min-w-12',
                  isHighlighted ? '!text-[#80A9FD]' : '!text-white',
                ),
              }}
            >
              <div
                className={clsx(
                  'absolute inset-0 flex items-center justify-center transition-opacity duration-300',
                  {
                    'opacity-0': !isHighlighted,
                    'opacity-100': isHighlighted,
                  },
                )}
              >
                {filledIcon}
              </div>
              <div
                className={clsx(
                  'absolute inset-0 flex items-center justify-center transition-opacity duration-300',
                  {
                    'opacity-0': isHighlighted,
                    'opacity-100': !isHighlighted,
                  },
                )}
              >
                {outlinedIcon}
              </div>
            </ListItemIcon>,
            name,
          )}
          <ListItemText
            primary={name}
            classes={{
              primary: clsx('transform-gpu transition-all duration-300', {
                '!text-[#80A9FD]': isHighlighted,
                'text-white': !isHighlighted,
                'translate-x-0 opacity-100': !isNavCollapsed,
                'w-0 -translate-x-60 opacity-0': isNavCollapsed,
              }),
            }}
          />
          {error && <InfoIcon className="size-4 text-red-700" />}
        </div>
        {hasChildren && (
          <ArrowDropDownIcon
            className={clsx('transition-transform', {
              'rotate-0': !isOpen,
              'rotate-180': isOpen,
            })}
          />
        )}
      </>
    );

    return (
      <>
        {withSalesTooltip(
          <ListItemButton
            classes={{
              root: clsx(
                '[&.MuiListItemButton-root]:pr-2 [&.MuiListItemButton-root]:hover:bg-[#12306d]',
                isChildItem
                  ? '[&.MuiListItemButton-root]:pl-8'
                  : '[&.MuiListItemButton-root]:pl-4',
                {
                  '[&.MuiListItemButton-root]:bg-[#12306d]': isHighlighted,
                },
                '[&.MuiListItemButton-root.group-[.has-selected-child]]:bg-[#1976d214]',
              ),
              selected: '[&.Mui-selected]:text-[#80A9FD]',
            }}
            selected={isSelected || singleChild?.isSelected}
            disabled={isDisabled}
            onMouseDown={handleClick}
            onContextMenu={(event) => {
              handleContextMenu(
                event,
                name,
                singleChild ? singleChild.route : route,
              );
            }}
          >
            {listItemContent}
          </ListItemButton>,
          disabledByClientPortal,
          disabledByModuleInvoiceRestriction,
          disabledByPackageBasicRestriction,
        )}

        {hasChildren && (
          <Collapse unmountOnExit in={isOpen} easing="ease-out" timeout="auto">
            <List
              disablePadding
              component="div"
              className={clsx('group', {
                'has-selected-child': hasSelectedChild,
              })}
            >
              {children.map((child: MainNavItemChildType) => (
                <NavItem
                  key={child.name}
                  isChildItem
                  item={child}
                  isNavCollapsed={isNavCollapsed}
                  handleItemClick={handleItemClick}
                  handleContextMenu={handleContextMenu}
                  withSalesTooltip={withSalesTooltip}
                  withTooltip={withTooltip}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  },
);

NavItem.displayName = 'NavItem';
