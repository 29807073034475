import {
  type GridApiRef,
  type GridColDef,
  type GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useMemo } from 'react';

import ExportService from '~/services/export.service';
import ToastService from '~/services/toast.service';

import Log from '~/utils/logging';

import { DatagridToolbarCustomComponent } from '~/ui/molecules/Datagrid';

type P = {
  readonly apiRef: GridApiRef;
  readonly excelColumns?: GridColDef[];
  readonly excelData?: any[];
  readonly multiplePdfDownload?: boolean;
  readonly noColumnsButton?: boolean;
  readonly noExportButton?: boolean;
  readonly noFilterButton?: boolean;
  readonly onExportCSVFromBackend?: (visibleColumns: string[]) => void;
  readonly onExportExcelFromBackend?: (visibleColumns: string[]) => void;
  readonly onExportInvoiceExcel?: () => void;
  readonly onMapDirectDeliveryNote?: () => void;
  readonly onMultiPermissionGrantEdit?: () => void;
  readonly onPdfExport?: () => void;
  readonly onRequestDeliveryNoteSignature?: () => void;
  readonly onShareDeliveryNote?: () => void;
  readonly productAnalyticsFeature?: string;
};

export const GridToolbar = ({
  apiRef,
  excelColumns,
  excelData,
  multiplePdfDownload,
  noColumnsButton,
  noExportButton,
  noFilterButton,
  onExportCSVFromBackend,
  onExportExcelFromBackend,
  onExportInvoiceExcel,
  onMapDirectDeliveryNote,
  onMultiPermissionGrantEdit,
  onPdfExport,
  onRequestDeliveryNoteSignature,
  onShareDeliveryNote,
  productAnalyticsFeature,
}: P) => {
  const handleExportFromBackend = (format: 'excel' | 'csv' = 'excel') => {
    const visibleColumns = apiRef.current
      .getVisibleColumns()
      .map(({ field }: { field: string }) => field);

    if (format === 'excel') {
      onExportExcelFromBackend(visibleColumns);
    } else {
      onExportCSVFromBackend(visibleColumns);
    }
  };

  const handleExportExcel = (withProductAnalyticsEvent: boolean) => {
    if (withProductAnalyticsEvent) {
      Log.productAnalyticsEvent(
        'Download Excel',
        productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
      );
    }

    if (excelData.length === 0) {
      ToastService.info([
        'Bitte wähle für den Excel-Download mindestens einen Eintrag aus der Tabelle aus.',
      ]);

      Log.productAnalyticsEvent(
        'No row selected in Excel-Download',
        productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    const rows = [excelColumns.map(({ headerName }) => headerName)];

    rows.push(
      ...excelData.map((data) => excelColumns.map(({ field }) => data[field])),
    );

    ExportService.exportExcel(rows);
  };

  const handleExcelInvoiceExport = () => {
    Log.productAnalyticsEvent(
      'Download invoice as Excel',
      Log.FEATURE.EXCEL_DOWNLOAD,
    );
    onExportInvoiceExcel();
  };

  const customComponents = useMemo(() => {
    const components = [];

    if (onExportCSVFromBackend) {
      const csvExportMenuItems = [
        {
          name: 'Download als CSV',
          onClick() {
            handleExportFromBackend('csv');
          },
        },
      ];

      components.push({
        icon: 'csv',
        menuItems: csvExportMenuItems,
        title: 'Exportieren',
      });
    }

    if ((excelData && excelColumns) || onExportExcelFromBackend) {
      const excelExportMenuItems = [
        {
          name: 'Download als Excel',
          onClick() {
            onExportExcelFromBackend
              ? handleExportFromBackend('excel')
              : handleExportExcel(true);
          },
        },
      ];

      if (onExportInvoiceExcel) {
        excelExportMenuItems.push({
          name: 'Download einzelne Rechnungen als Excel',
          onClick() {
            handleExcelInvoiceExport();
          },
        });
      }

      components.push({
        icon: 'excel',
        menuItems: excelExportMenuItems,
        title: 'Excel Download',
      });
    }

    if (onPdfExport) {
      const pdfExportMenuItems = multiplePdfDownload
        ? [
            {
              name: 'Download als PDF',
              onClick() {
                onPdfExport(ExportService.DOWNLOAD_OPTION.MERGE);
              },
            },
          ]
        : [
            {
              name: 'Download als PDF',
              onClick() {
                onPdfExport();
              },
            },
          ];

      components.push({
        icon: 'pdf',
        menuItems: pdfExportMenuItems,
        title: 'PDF Download',
      });
    }

    if (onMultiPermissionGrantEdit) {
      components.push({
        icon: 'permissionGrant',
        menuItems: [
          {
            name: 'Berechtigungen vergeben',
            onClick: onMultiPermissionGrantEdit,
          },
        ],
        title: 'Berechtigungen vergeben',
      });
    }

    if (onRequestDeliveryNoteSignature) {
      components.push({
        icon: 'requestSignature',
        menuItems: [
          {
            name: 'Signaturen anfordern',
            onClick: onRequestDeliveryNoteSignature,
          },
        ],
        title: 'Signaturen anfordern',
      });
    }

    if (onShareDeliveryNote) {
      components.push({
        icon: 'shareDeliveryNote',
        menuItems: [
          {
            name: 'Lieferungen teilen',
            onClick: onShareDeliveryNote,
          },
        ],
        title: 'Lieferungen teilen',
      });
    }

    if (onMapDirectDeliveryNote) {
      components.push({
        icon: 'mapDirectDeliveryNote',
        menuItems: [
          {
            name: 'Standort zuweisen',
            onClick: onMapDirectDeliveryNote,
          },
        ],
        title: 'Standort zuweisen',
      });
    }

    return components;
  }, [
    apiRef,
    excelColumns,
    excelData,
    multiplePdfDownload,
    onExportExcelFromBackend,
    onExportInvoiceExcel,
    onMapDirectDeliveryNote,
    onMultiPermissionGrantEdit,
    onPdfExport,
    onRequestDeliveryNoteSignature,
    onShareDeliveryNote,
  ]);

  return (
    <GridToolbarContainer className="flex gap-2">
      {noColumnsButton ? null : <GridToolbarColumnsButton />}
      {noFilterButton ? null : <GridToolbarFilterButton />}
      {noExportButton ? null : (
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      )}
      {customComponents.map(({ icon, menuItems, title }) => (
        <DatagridToolbarCustomComponent
          key={title}
          title={title}
          icon={icon}
          menuItems={menuItems}
        />
      ))}
    </GridToolbarContainer>
  );
};
